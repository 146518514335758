import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import(/* webpackChunkName: "home" */ "../views/Home.vue"),
  },
  {
    path: "/solution",
    name: "solution",
    redirect: {
      path: "/solution/1",
    },
    component: () =>
      import(/* webpackChunkName: "solution" */ "../views/solution/index.vue"),
    children: [
      {
        path: "1",
        component: () =>
          import(
            /* webpackChunkName: "solution.1" */ "../views/solution/S1.vue"
          ),
      },
      {
        path: "2",
        component: () =>
          import(
            /* webpackChunkName: "solution.2" */ "../views/solution/S2.vue"
          ),
      },
      {
        path: "3",
        component: () =>
          import(
            /* webpackChunkName: "solution.3" */ "../views/solution/S3.vue"
          ),
      },
      {
        path: "4",
        component: () =>
          import(
            /* webpackChunkName: "solution.4" */ "../views/solution/S4.vue"
          ),
      },
      {
        path: "5",
        component: () =>
          import(
            /* webpackChunkName: "solution.5" */ "../views/solution/S5.vue"
          ),
      },
      {
        path: "6",
        component: () =>
          import(
            /* webpackChunkName: "solution.6" */ "../views/solution/S6.vue"
          ),
      },
      {
        path: "7",
        component: () =>
          import(
            /* webpackChunkName: "solution.7" */ "../views/solution/S7.vue"
          ),
      },
      {
        path: "8",
        component: () =>
          import(
            /* webpackChunkName: "solution.8" */ "../views/solution/S8.vue"
          ),
      },
    ],
  },
  {
    path: "/smart_construction",
    name: "smart_construction",
    component: () =>
      import(
        /* webpackChunkName: "smart_construction" */ "../views/SmartConstruction.vue"
      ),
  },
  {
    path: "/support",
    name: "support",
    component: () =>
      import(/* webpackChunkName: "support" */ "../views/Support.vue"),
  },
  {
    path: "/download",
    name: "download",
    component: () =>
      import(/* webpackChunkName: "download" */ "../views/Download.vue"),
  },
  {
    path: "/login",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/Login.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: "/",
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { y: 0 };
    }
  },
});

export default router;
