const baseUrl = `${process.env.BASE_URL}public/img/smart_construction`;

const devices = [
  {
    id: "001",
    name: "壁挂考勤机",
    desc: "人脸识别考勤，避免不是本人打卡，人脸识别准确度高，安全性好，识别性能不受环境光线的影响，完全非接触，可避免细菌传染，更加健康卫生。语音提示是否打卡成功，无需连接电脑，即可完成人员考勤",
    cover: `${baseUrl}/list/头图/壁挂考勤机.jpg`,
    banner: [`${baseUrl}/list/头图/壁挂考勤机.jpg`],
    content: [
      {
        path: `${baseUrl}/list/壁挂考勤机.jpg`,
        width: 750,
        height: 4564,
      },
    ],
  },
  {
    id: "002",
    name: "人脸识别器",
    desc: "双目多模态活体检测，含合成图防御算法，全面阻挡各类作弊攻击；对于模具、图片、视频、面具等作弊刷脸攻击，攻击抵抗率高达99.9%以上。离线支持 50000 张人脸底库，轻松应对业务中任何时候人员暴增",
    cover: `${baseUrl}/list/头图/人脸识别器.jpg`,
    banner: [`${baseUrl}/list/头图/人脸识别器.jpg`],
    content: [
      {
        path: `${baseUrl}/list/人脸识别器.jpg`,
        width: 750,
        height: 4312,
      },
    ],
  },
  {
    id: "003",
    name: "闸机（普通闸机）",
    desc: "集考勤、门禁、人脸识别功能于一体，完美匹配工地实名制要求，效率高、成本小，可对接云筑网和各地政府两制平台闸机联网，通过手机端操作界面可以实现人员权限管理、实名制人员信息录入、考勤报表统计导出…等功能",
    cover: `${baseUrl}/list/头图/闸机/闸机4.jpg`,
    banner: [
      `${baseUrl}/list/头图/闸机/闸机2.jpg`,
      `${baseUrl}/list/头图/闸机/闸机.jpg`,
      `${baseUrl}/list/头图/闸机/闸机3.jpg`,
    ],
    content: [
      {
        path: `${baseUrl}/list/闸机.jpg`,
        width: 750,
        height: 8240,
      },
    ],
  },
  {
    id: "0015",
    name: "无感人脸摄像机",
    desc: "无感考勤系统只需在工地进入口设立人脸识别摄像机，接入墨器星盘系统，利用精准的人脸识别算法，主动识别过往人员的面部信息，并在前端进行员工信息对比，确认是工地内部人员后完成考勤，并将考勤信息在显示屏上显示出来，不仅可以提醒员工打卡成功，而且支持多人同时考勤，解决打卡排长队的老问题。",
    cover: `${baseUrl}/list/头图/无感考勤.png`,
    banner: [`${baseUrl}/list/头图/无感考勤.png`],
    content: [
      {
        path: `${baseUrl}/list/无感考勤.jpg`,
        width: 750,
        height: 6050,
      },
    ],
  },
  {
    id: "004",
    name: "身份证读卡器",
    desc: "墨器专为智能身份证识别系统开发定制的一款免驱多功能智能卡读写器，具备身份证信息脱机读取、身份证物理ID读取、IC卡信息读取、电子标签读取等功能",
    cover: `${baseUrl}/list/头图/身份证读卡器/身份证读卡器.jpg`,
    banner: [
      `${baseUrl}/list/头图/身份证读卡器/1.jpg`,
      `${baseUrl}/list/头图/身份证读卡器/身份证读卡器.jpg`,
    ],
    content: [
      {
        path: `${baseUrl}/list/身份证读卡器.jpg`,
        width: 750,
        height: 5716,
      },
    ],
  },
  {
    id: "005",
    name: "5G+AI智能摄像机",
    desc: "5G支持搭载40路的1080P高清视频，可以实现整个工地全方位立体化的可视化管理。AI视频监控平台，可以通过AI十余种算法对图形进行识别，自动抓拍现场存在的安全隐患以及人员违规行为，如未佩戴安全帽、未穿反光衣、明火、烟雾等情况",
    cover: `${baseUrl}/list/头图/5G_AI智能摄像机.jpg`,
    banner: [`${baseUrl}/list/头图/5G_AI智能摄像机.jpg`],
    content: [
      {
        path: `${baseUrl}/list/5G_AI智能摄像机.jpg`,
        width: 750,
        height: 5500,
      },
    ],
  },
  {
    id: "006",
    name: "车道闸",
    desc: "自动识别车牌，掌握车辆进出情况。同时与系统内提前录入的车辆信息进行对比，根据对比结果决定是否发行。实现对泥头车“一不准进、三不准出的管理”",
    cover: `${baseUrl}/list/头图/车道闸.jpg`,
    banner: [`${baseUrl}/list/头图/车道闸.jpg`],
    content: [
      {
        path: `${baseUrl}/list/车道闸.jpg`,
        width: 750,
        height: 7116,
      },
    ],
  },
  {
    id: "007",
    name: "塔机监测系统",
    desc: "实时监控塔吊设备的运行数据，如力矩、载重、幅度等；实时监控群塔作业，实现塔吊防碰撞，并进行分级报警；限制塔吊旋转角度和小车限位、司机实名制考勤，刷脸启动设备，所有运行数据实时上传至墨器系统，云端保存数据，数据可随时调取",
    cover: `${baseUrl}/list/头图/塔机监测系统.jpg`,
    banner: [`${baseUrl}/list/头图/塔机监测系统.jpg`],
    content: [
      {
        path: `${baseUrl}/list/塔机监测系统.jpg`,
        width: 750,
        height: 4512,
      },
    ],
  },
  {
    id: "008",
    name: "升降机监测",
    desc: "升降机安全监控是基于互联网传感器、云计算、嵌入式、数据采集和融合、无线传输、远程数据通信等技术研发，高效率地完整实现实时监控与声光预警报警、数据远传、平台集中监控与管理功能。",
    cover: `${baseUrl}/list/头图/升降机监测.jpg`,
    banner: [`${baseUrl}/list/头图/升降机监测.jpg`],
    content: [
      {
        path: `${baseUrl}/list/升降机监测.jpg`,
        width: 750,
        height: 5958,
      },
    ],
  },
  {
    id: "009",
    name: "扬尘噪声监测",
    desc: "实现对TSP、PM2.5、PM10、噪声、空气温湿度等环境指标进行监控，同步联动降尘装置，数据超标智能启动，实现设备自动远程联动控制，提升环境整改效率",
    cover: `${baseUrl}/list/头图/扬尘噪音监测.jpg`,
    banner: [`${baseUrl}/list/头图/扬尘噪声监测.jpg`],
    content: [
      {
        path: `${baseUrl}/list/扬尘噪音监测.jpg`,
        width: 750,
        height: 5764,
      },
    ],
  },
  {
    id: "016",
    name: "智能喷淋系统",
    desc: "智能喷淋可安装在塔吊、围墙、建筑体、基坑等场景，结合墨器星盘与环境监测设备，自动监测扬尘环境，数据超标智能启动，实现设备自动远程联动控制，提升环境整改效率。",
    cover: `${baseUrl}/list/头图/智能喷淋/智能喷淋2.png`,
    banner: [
      `${baseUrl}/list/头图/智能喷淋/智能喷淋1.png`,
      `${baseUrl}/list/头图/智能喷淋/智能喷淋2.png`,
    ],
    content: [
      {
        path: `${baseUrl}/list/智能喷淋.jpg`,
        width: 750,
        height: 8150,
      },
    ],
  },
  {
    id: "010",
    name: "配电箱监测系统",
    desc: "实时监测线路中剩余电流、线缆温度、配电箱温度等数据，一旦某个数据超出设定的安全值，系统将会自动报警，并将报警信息发送到用户的手机",
    cover: `${baseUrl}/list/头图/配电箱监测系统.jpg`,
    banner: [`${baseUrl}/list/头图/配电箱监测系统.jpg`],
    content: [
      {
        path: `${baseUrl}/list/配电箱监测系统.jpg`,
        width: 750,
        height: 4516,
      },
    ],
  },
  {
    id: "011",
    name: "全高闸",
    desc: "转闸能方便金融IC卡ID卡和条形码指纹二代身份证等读卡识别设备的使用，为出入人员提供文明有序的通行方式，有效管理人员进出，通过选配各种身份证识别系统设备和采用性能可靠的安全保护装置、报警装置、方向指示、及扩展led计数显示界面。协调实现对通道智能化控制与管理等",
    cover: `${baseUrl}/list/头图/全高闸.jpg`,
    banner: [`${baseUrl}/list/头图/全高闸.jpg`],
    content: [
      {
        path: `${baseUrl}/list/全高闸.jpg`,
        width: 750,
        height: 6060,
      },
    ],
  },
  {
    id: "012",
    name: "整体集装箱",
    desc: "集装箱使用范围较广、组装灵活、拆装方便、环保节能、耐候性强，具有良好的耐酸、碱、盐雾等腐蚀性，适合各种潮湿、腐蚀性强的环境使用。具有防水、隔音、保温、密封、易清洁、易维护、外表美观",
    cover: `${baseUrl}/list/头图/集装箱.jpg`,
    banner: [`${baseUrl}/list/头图/集装箱.jpg`],
    content: [
      {
        path: `${baseUrl}/list/整体集装箱.jpg`,
        width: 750,
        height: 5964,
      },
    ],
  },
  {
    id: "017",
    name: "工地广播系统",
    desc: "智慧工地广播系统，助力建筑行业的工地信息化发展，将融合通信指挥技术应用于建筑工地日常维护和应急处理管理中，实现工地管理的智慧运营。 方案以IP网络高清音视频通讯技术为基础，构造了一套以施工人员为中心的、覆盖工地的施工生产现场、出入口、电梯、办公、宿舍、保安室和指挥调度中心七个区域的对讲系统，系统实现应急会商、紧急求助对讲、应急广播、应急指挥、电梯控制、消防联动、门禁控制、监控和指挥中心通信系统的融合，对施工人员的各类求助提供快捷有效的服务，保障人员的生命安全，帮助工地管理行业最大化地提升管理效率与经济效益。",
    cover: `${baseUrl}/list/头图/工地广播系统.png`,
    banner: [`${baseUrl}/list/头图/工地广播系统.png`],
    content: [
      {
        path: `${baseUrl}/list/工地广播系统.jpg`,
        width: 750,
        height: 7130,
      },
    ],
  },
  {
    id: "013",
    name: "手持考勤机",
    desc: "墨器智能人脸识别考勤终端是一款基于Android平台，具备考勤平台对接、人员信息授权、存储、人脸对比考勤打卡功能的设备、可实现现场人脸识别打卡，考勤记录同时上传到墨器平台，通过平台进行人员考勤数据统计管理、具有便携小巧、简单易用等特点，广泛应用于工地、企业园区等考勤领域",
    cover: `${baseUrl}/list/头图/手持考勤机.jpg`,
    banner: [`${baseUrl}/list/头图/手持考勤机.jpg`],
    content: [
      {
        path: `${baseUrl}/list/手持考勤机.jpg`,
        width: 750,
        height: 4665,
      },
    ],
  },
  {
    id: "014",
    name: "LED显示屏",
    desc: "显示单元采用压铸箱体设计，一次性整体压铸，具有防电磁辐射、抗冲击、防尘静音的优势，箱体自带快锁锁扣，满足快拆快装。悬浮磁铁设计，提高显示屏表面平整度，自带护角装置，避免边角磕碰，一体化驱动设计，提高箱体稳定性。模块化拼接，支持各种尺寸的显示屏幕需求",
    cover: `${baseUrl}/list/头图/LED显示屏.jpg`,
    banner: [`${baseUrl}/list/头图/LED显示屏.jpg`],
    content: [
      {
        path: `${baseUrl}/list/LED显示屏.jpg`,
        width: 750,
        height: 4909,
      },
    ],
  },
];

export default devices;
