<template>
  <div class="m-nav" flex="cross:center">
    <img class="logo" src="@/assets/img/菜单_logo@3x.png" alt="logo" />
    <a class="item" href="https://www.emodor.com">首页</a>
    <span :class="[path.match('/solution') || path === '/smart_construction' ? 'active' : '' , 'item']">
      <span>解决方案</span>
      <div class="item_float_wrap">
        <router-link class="item_flow" to="/solution/3">
          建筑劳务全流程精细化管理
        </router-link>
        <router-link class="item_flow" to="/solution/7">
          实名制+电子合同解决方案
        </router-link>
        <router-link class="item_flow" to="/solution/6">
          实名制+三级安全教育解决方案
        </router-link>
        <router-link class="item_flow" to="/solution/4">
          实名制+可视化施工管理解决方案
        </router-link>
        <router-link class="item_flow" to="/smart_construction">
          实名制+智慧工地解决方案
        </router-link>
      </div>
    </span>
    <!-- <router-link class="item" to="/solution">解决方案</router-link> -->
    <a class="item" target="_blank" href="https://moji.emodor.com/service-case">
      成功案例
    </a>
    <!-- <router-link class="item" to="/smart_construction">智慧工地</router-link> -->
    <span :class="[path === '/download' || path === '/support' ? 'active' : '' , 'item']">
      <span>客户成功</span>
      <div class="item_float_wrap">
        <router-link class="item_flow" to="/support">
          服务与支持
        </router-link>
        <router-link class="item_flow" to="/download">
          产品体验
        </router-link>
      </div>
    </span>
    <!-- <router-link class="item" to="/support">服务支持</router-link> -->
    <span :class="[path.match('/about-us') ? 'active' : '' , 'item']">
      <span>关于我们</span>
      <div class="item_float_wrap">
        <a class="item_flow" target="_blank" href="https://moji.emodor.com/about-us/company-desc">
          公司介绍
        </a>
        <a class="item_flow" target="_blank" href="https://moji.emodor.com/about-us/company-desc">
          发展历程
        </a>
        <a class="item_flow" target="_blank" href="https://moji.emodor.com/about-us/news">
          新闻动态  
        </a>
        <a class="item_flow" target="_blank" href="https://moji.emodor.com/about-us/contact-us">
          联系我们
        </a>
      </div>
    </span>
    <!-- <a class="item" target="_blank" href="https://www.emodor.com/about-us">关于我们</a> -->
    <!-- <router-link class="item" to="/download">App下载</router-link> -->
    <span flex-box="1" />
    <img class="tel_icon" src="@/assets/img/电话@3x.png" alt="售前咨询" />
    <span class="tel">400-004-0686</span>
    <span class="btn" @click="showTrialModal">试用体验</span>
    <router-link class="btn" :style="{ marginLeft: '0.6rem' }" to="/login">用户登录</router-link>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  data: () => ({
    path: ''
  }),
  watch: {
    $route:{
      immediate:true,
      handler(){
        this.path = this.$route.path
      },
    },
  },
  methods: {
    ...mapActions(["showTrialModal"]),
    onSolutionClick() {
      //
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/theme.scss";

.m-nav {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: $navHeight;
  background-image: linear-gradient(#0f1b35, #0d2143);
  padding: 0 2rem;
  border-bottom: 1px solid #35607b;
  box-sizing: border-box;

  .logo {
    display: block;
    width: 6.4rem;
    height: 2rem;
    margin-right: 0.7rem;
  }

  .item {
    position: relative;
    font-size: 0.8rem;
    color: #fff;
    margin-left: 2.5rem;
    text-decoration: none;
    line-height: 3.6rem;

    &:hover,
    &.router-link-active, &.active {
      color: #93f4ff;
      font-weight: bold;
    }

    &.router-link-active::after, &.active::after {
      display: block;
    }

    &::after {
      display: none;
      content: "";
      position: absolute;
      left: 50%;
      bottom: 0;
      width: 3rem;
      height: 1.1rem;
      background: url("~@/assets/bg/编组 6@3x.png");
      background-size: cover;
      margin-left: -1.5rem;
    }

    .item_float_wrap {
      position: absolute;
      top: -100rem;
      padding: 1rem 0;
      width: fit-content;
      left: 50%;
      background-color: #0d2143;
      transform: translateX(-50%);
      text-align: center;
      z-index: -1;
      overflow: hidden;
      line-height: 1.2rem;
    }
    &:hover .item_float_wrap {
      z-index: 1;
      top: 3.6rem;
      overflow: hidden;
    }

    .item_flow {
      display: block;
      white-space: nowrap;
      padding: 0.5rem 0.88rem;
      color: #fff;
      text-decoration: none;
      cursor: pointer;
      &:hover,
      &.router-link-active {
        color: #93f4ff;
        font-weight: bold;
      }
    }
  }

  .tel_icon {
    display: block;
    width: 1.2rem;
    height: 1.2rem;
    margin-right: 0.4rem;
  }

  .tel {
    margin-right: 1.2rem;
  }

  .btn {
    display: block;
    width: 4.95rem;
    height: 1.75rem;
    background-color: #0078f0;
    border-radius: 0.3rem;
    font-size: 0.8rem;
    font-weight: bold;
    color: #fff;
    line-height: 1.75rem;
    text-align: center;
    text-decoration: none;
    cursor: pointer;

    &:hover {
      background-color: #045af2;
    }
  }
}
</style>
