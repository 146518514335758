<template>
  <div class="root">
    <router-view />
    <Nav />
    <TrialModal />
    <TrialSuccModal v-if="trialSuccModal.visible" />
    <DeviceDetail />
    <FloatNav />
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Nav from "@/components/Nav.vue";
import FloatNav from "@/components/FloatNav.vue";
import TrialModal from "@/components/TrialModal.vue";
import TrialSuccModal from "@/components/TrialSuccModal.vue";
import DeviceDetail from "@/components/DeviceDetail.vue";

export default {
  name: "Home",
  components: {
    Nav,
    FloatNav,
    TrialModal,
    TrialSuccModal,
    DeviceDetail,
  },
  mounted() {
    document.addEventListener("click", () => {
      this.hideDeviceDetail();
    });
  },
  computed: {
    ...mapState(["trialSuccModal"]),
  },
  methods: {
    ...mapActions(["hideDeviceDetail"]),
  },
};
</script>

<style lang="scss">
@import "@/theme.scss";

body {
  background-color: $navBgc;
  color: #fff;
  font-family: PingFangSC, PingFangSC-Regular, "Noto Sans SC", "Microsoft Yahei",
    SimHei, "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.first_pic_for_spider {
  position: absolute;
  left: -200%;
  top: 0;
}
</style>
