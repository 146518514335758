export function isStrEmpty(str, label) {
  console.log(str, label);
  if (!str || !/\S/.test(str)) {
    return {
      msg: `请填写${label}`,
    };
  }
  return null;
}

export function isStrOverflow(str, max, label) {
  if (str.length > max) {
    return {
      msg: `${label}不能大于${max}个字`,
    };
  }
  return null;
}

export function checkPhone(str, label) {
  if (!/^\d{11}$/.test(str)) {
    return {
      msg: `${label}格式错误`,
    };
  }
  return null;
}

export function validate(data) {
  for (let i = 0; i < data.length; i++) {
    const { fn, opt } = data[i];
    const res = fn.apply(null, opt);
    if (res && res.msg) {
      return res;
    }
  }
  return {};
}
