const api = {
  getQrcode: {
    url: "/bi-api/api/sys/getMiniQrCode",
  },
  getQrcodeStatus: {
    url: "/bi-api/api/sys/checkLogin",
  },
  applyRegisterCompany: {
    url: "/bi-api/api/company/applyRegisterCompany",
    method: "post",
  },
};

export default api;
