<template>
  <div class="float_nav" :style="{ transform: `scale(${scale})` }">
    <div class="item">
      <img class="i" src="@/assets/img/电话@3x.png" alt="售前咨询" />
      <div class="t">售前</div>
      <div class="t">咨询</div>
      <div class="info_box i1">
        <div class="t1">售前电话：400-004-0686 转 2</div>
        <div class="t2">售前咨询、产品体验、业务合作</div>
        <div class="t3">咨询顾问：沈女士</div>
        <div class="t4">联系方式：199 6628 9230</div>
        <div class="t5">电子邮箱：marketing@emodor.com</div>
        <img class="p1" src="@/assets/img/smt.png" alt="沈女士" />
      </div>
    </div>
    <div class="item">
      <img class="i" src="@/assets/img/售后@3x.png" alt="售后服务" />
      <div class="t">售后</div>
      <div class="t">服务</div>
      <div class="info_box i2">
        <div class="t1">售后电话：400-004-0686 转 1</div>
        <div class="t2">售后服务、问题解答</div>
        <div class="t3">售后服务热线</div>
        <div class="t4">7*24小时服务，给您带来全新服务体验</div>
      </div>
    </div>
    <div class="item" @click="showTrialModal">
      <img class="i" src="@/assets/img/申请@3x.png" alt="申请体验" />
      <div class="t">申请</div>
      <div class="t">体验</div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      scale: 1,
    };
  },
  mounted() {
    const onResize = () => {
      if (!window.getComputedStyle || !window.document.documentElement) {
        return;
      }
      const size = window.getComputedStyle(window.document.documentElement)[
        "font-size"
      ];
      const scale = size.replace("px", "") / (window.BASE_FONT_SIZE || 20);
      this.scale = scale;
    };
    window.addEventListener("resize", onResize);
    this.onResize = onResize;
    onResize();
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    ...mapActions(["showTrialModal"]),
  },
};
</script>

<style lang="scss" scoped>
.float_nav {
  position: fixed;
  right: 0;
  bottom: 74px;
  z-index: 2;
  transform-origin: right bottom;

  .item {
    position: relative;
    background-color: #4a4a4a;
    border-radius: 8px 0 0 8px;
    padding: 10px 8px 7px;
    margin-top: 10px;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
      background-color: #2574ff;

      .info_box {
        right: 100%;
        bottom: 0;
        opacity: 1;
      }
    }

    .i {
      display: block;
      width: 24px;
      height: 24px;
      margin: 0 auto 5px;
    }

    .t {
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      text-align: center;
    }
  }

  .info_box {
    position: absolute;
    right: -1000px;
    bottom: 20%;
    width: 456px;
    background-color: #2574ff;
    border-radius: 8px;
    margin-right: 8px;
    padding: 22px 24px;
    box-sizing: border-box;
    opacity: 0;
    transition: opacity 0.5s, bottom 0.5s;

    &.i1 {
      .t1 {
        font-weight: 600;
        font-size: 20px;
        color: #fff;
        line-height: 30px;
      }

      .t2,
      .t4,
      .t5 {
        margin-top: 6px;
        opacity: 0.8;
        font-weight: 400;
        font-size: 18px;
        color: #fff;
        line-height: 24px;
      }

      .t3 {
        margin-top: 24px;
        font-weight: 600;
        font-size: 18px;
        color: #fff;
        line-height: 24px;
      }

      .t4 {
        margin-top: 8px;
      }

      .t5 {
        margin-top: 6px;
      }

      .p1 {
        position: absolute;
        right: 24px;
        bottom: 22px;
        width: 86px;
        height: 86px;
      }
    }

    &.i2 {
      .t1 {
        font-weight: 600;
        font-size: 20px;
        color: #fff;
        line-height: 30px;
      }

      .t2,
      .t4 {
        margin-top: 6px;
        opacity: 0.8;
        font-weight: 400;
        font-size: 18px;
        color: #fff;
        line-height: 24px;
      }

      .t3 {
        margin-top: 24px;
        font-weight: 600;
        font-size: 18px;
        color: #fff;
        line-height: 24px;
      }

      .t4 {
        margin-top: 8px;
      }
    }
  }
}
</style>
