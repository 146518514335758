<template>
  <div
    :class="['m-device-detail', deviceDetail.visible ? 'z-show' : '']"
    flex="dir:top"
    @click.stop
  >
    <div
      class="head"
      @click="hideDeviceDetail"
      flex-box="0"
      flex="main:justify cross:center"
    >
      <div class="ttl">设备详情</div>
      <img class="close_btn" src="@/assets/img/ic_关闭@3x.png" alt="关闭" />
    </div>
    <div class="content" flex-box="1" v-if="deviceDetail.info">
      <div class="banners">
        <swiper
          @swiper="(sw) => (banner.swiper = sw)"
          :controller="{ control: banner.swiper }"
          @slideChange="(e) => (banner.crtIndex = e.realIndex)"
          class="swp"
          :autoplay="{ delay: 3000 }"
        >
          <swiper-slide
            class="sld"
            :style="{ backgroundImage: `url(${s})` }"
            v-for="s in deviceDetail.info.banner"
            :key="s"
          ></swiper-slide>
        </swiper>
        <div class="pagination" flex="main:center">
          <div
            :class="['dot', banner.crtIndex === i ? 'z-crt' : '']"
            @click="banner.swiper.slideTo(i)"
            v-for="(item, i) in deviceDetail.info.banner"
            :key="item"
          />
        </div>
      </div>
      <div class="info_box">
        <div class="name">{{ deviceDetail.info.name }}</div>
        <div class="desc">{{ deviceDetail.info.desc }}</div>
        <a
          class="btn"
          href="http://p.qiao.baidu.com/cps/chat?siteId=13160731&userId=27403999&siteToken=839057cc7afae96bcdd508339dc7a783"
          target="_blank"
        >
          咨询服务
        </a>
      </div>
      <div class="pics">
        <img
          :src="p.path"
          :alt="p.path"
          v-for="p in deviceDetail.info.content"
          :key="p.path"
          class="p"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";

const banner = {
  swiper: null,
  crtIndex: 0,
};

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  computed: {
    ...mapState(["deviceDetail"]),
  },
  data() {
    return {
      banner,
    };
  },
  methods: {
    ...mapActions(["hideDeviceDetail"]),
  },
};
</script>

<style lang="scss" scoped>
@import "@/theme.scss";

.m-device-detail {
  position: fixed;
  top: $navHeight;
  right: -29rem;
  bottom: 0;
  width: 29rem;
  background-color: #fff;
  box-sizing: border-box;
  transition: transform 0.3s;
  z-index: 5;

  &.z-show {
    transform: translate3d(-29rem, 0, 0);
  }

  .head {
    height: 4.5rem;
    margin: 0 2rem;
    border-bottom: 1px solid #ddd;

    .ttl {
      font-size: 1.2rem;
      font-weight: bold;
      color: #9a9a9a;
    }

    .close_btn {
      display: block;
      width: 1.5rem;
      height: 1.5rem;
      cursor: pointer;
    }
  }

  .banners {
    width: 25rem;
    margin-top: 1.5rem;

    .swp {
      height: 25rem;
      width: 100%;
      background-color: #ccc;

      .sld {
        width: 100%;
        background-position: center center;
        background-size: cover;
      }
    }

    .pagination {
      margin-top: -1.2rem;
      position: relative;
      z-index: 1;

      .dot {
        width: 0.4rem;
        height: 0.4rem;
        opacity: 0.4;
        background-color: #2574ff;
        border-radius: 50%;
        cursor: pointer;
        transition: opacity 0.3s;
        margin-right: 0.4rem;

        &:last-child {
          margin-right: 0;
        }

        &.z-crt {
          opacity: 1;
        }
      }
    }
  }

  .info_box {
    margin-top: 1.5rem;
    width: 25rem;

    .name {
      font-size: 1.5rem;
      font-weight: bold;
      color: #1a1a1a;
      line-height: 2.25rem;
    }

    .desc {
      margin-top: 0.8rem;
      font-size: 0.9rem;
      color: #666666;
      line-height: 1.5rem;
    }

    .btn {
      margin-top: 1.35rem;
      display: block;
      width: 8rem;
      height: 2.2rem;
      background-color: #2574ff;
      border-radius: 0.5rem;
      text-decoration: none;
      font-size: 1rem;
      font-weight: bold;
      text-align: center;
      color: #fff;
      line-height: 2.2rem;

      &:hover {
        background-color: #045af2;
      }
    }
  }

  .pics {
    margin-top: 3rem;
    width: 25rem;

    .p {
      display: block;
      width: 25rem;
    }
  }

  .content {
    width: 100%;
    overflow: auto;
    padding: 0 0 2rem 2rem;
    box-sizing: border-box;
  }
}
</style>
