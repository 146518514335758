<template>
  <div
    class="m-trial_modal"
    flex="main:center cross:center"
    v-if="trialModal.visible"
  >
    <div class="main_box">
      <div class="title">申请体验墨器星盘</div>
      <div class="form_box" flex="main:top cross:center">
        <div class="col">
          <div class="row">
            <div class="label">企业名称：</div>
            <input
              class="ipt"
              v-model="companyName"
              type="text"
              placeholder="请输入"
            />
          </div>
          <div class="row">
            <div class="label">职位：</div>
            <input
              class="ipt"
              v-model="position"
              type="text"
              placeholder="请输入"
            />
          </div>
          <div class="row">
            <div class="label">手机号码：</div>
            <input
              class="ipt"
              v-model="phone"
              type="text"
              placeholder="请输入"
            />
          </div>
        </div>
        <div class="col">
          <div class="row">
            <div class="label">您的称呼：</div>
            <input
              class="ipt"
              v-model="contact"
              type="text"
              placeholder="请输入"
            />
          </div>
          <div class="row">
            <div class="label">需求描述：</div>
            <textarea
              class="txt"
              placeholder="如：希望了解实名制考勤、质量安全管理、智慧工地等"
              v-model="description"
            ></textarea>
          </div>
        </div>
      </div>
      <div class="action" flex="dir:right">
        <div class="btn submit" @click="submit">提交申请</div>
        <div class="btn cancel" @click="hideTrialModal">取消</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import req from "@/req";
import { isStrEmpty, isStrOverflow, checkPhone, validate } from "@/utils";

export default {
  data() {
    return {
      companyName: "",
      position: "",
      phone: "",
      contact: "",
      description: "",
    };
  },
  computed: {
    ...mapState(["trialModal"]),
  },
  mounted() {
    this.uploading = false;
  },
  methods: {
    ...mapActions(["hideTrialModal", "showTrialSuccModal"]),
    resetForm() {
      this.companyName = "";
      this.position = "";
      this.phone = "";
      this.contact = "";
      this.description = "";
    },
    checkData() {
      const { companyName, position, phone, contact, description } = this;
      const validateRes = validate([
        { fn: isStrEmpty, opt: [companyName, "企业名称"] },
        { fn: isStrOverflow, opt: [companyName, 30, "企业名称"] },

        { fn: isStrEmpty, opt: [contact, "称呼"] },
        { fn: isStrOverflow, opt: [contact, 20, "称呼"] },

        { fn: isStrEmpty, opt: [position, "职位"] },
        { fn: isStrOverflow, opt: [position, 30, "职位"] },

        { fn: isStrEmpty, opt: [phone, "手机号码"] },
        { fn: checkPhone, opt: [phone, "手机号码"] },

        { fn: isStrEmpty, opt: [description, "需求描述"] },
        { fn: isStrOverflow, opt: [description, 100, "需求描述"] },
      ]);
      if (validateRes && validateRes.msg) {
        window.alert(validateRes.msg);
        return null;
      }
      return {
        companyName,
        position,
        phone,
        contact,
        description,
      };
    },
    submit() {
      const that = this;
      const data = this.checkData();
      if (!data) {
        return;
      }
      if (this.uploading) {
        return;
      }
      this.uploading = true;
      req.sys
        .applyRegisterCompany(JSON.stringify(data))
        .then((res) => {
          that.uploading = false;
          if (res.code !== 0) {
            req.err.show(res.msg);
            return;
          }
          that.resetForm();
          that.showTrialSuccModal();
          that.hideTrialModal();
        })
        .catch((err) => {
          that.uploading = false;
          req.err.show(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.m-trial_modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(9, 21, 44, 0.6);
  z-index: 50;

  .main_box {
    width: 54rem;
    padding: 3rem 2.5rem;
    background-color: #fff;
    border-radius: 0.6rem;
    box-sizing: border-box;

    .title {
      font-size: 2rem;
      font-weight: bold;
      color: #1a1a1a;
      line-height: 3rem;
    }

    .form_box {
      margin-top: 1.8rem;

      .col {
        flex-grow: 1;
        width: 50%;
        margin-right: 1rem;

        &:last-child {
          margin-right: 0;
        }
      }

      .row {
        margin-bottom: 1.4rem;

        &:last-child {
          margin-bottom: 0;
        }

        .label {
          font-size: 0.9rem;
          color: #666;
          line-height: 1.2rem;
        }

        .ipt,
        .txt {
          display: block;
          width: 24rem;
          background-color: #fff;
          border: 1px solid #ddd;
          border-radius: 0.1rem;
          margin-top: 0.6rem;
          box-sizing: border-box;
          font-size: 0.9rem;

          &::-webkit-input-placeholder {
            color: #9a9a9a;
          }
        }

        .ipt {
          height: 2.5rem;
          padding-left: 1rem;
          padding-right: 1rem;
        }

        .txt {
          height: 8.2rem;
          padding: 0.65rem 1rem;
          resize: none;
          line-height: 1.2rem;
        }
      }
    }

    .action {
      margin-top: 2.2rem;

      .btn {
        width: 8rem;
        height: 2.2rem;
        border: 1px solid #ddd;
        border-radius: 0.3rem;
        text-align: center;
        line-height: 2.2rem;
        font-size: 1rem;
        color: #9a9a9a;
        margin-right: 1rem;
        box-sizing: border-box;
        cursor: pointer;

        &:first-child {
          margin-right: 0;
        }

        &.submit {
          background-color: #0078f0;
          border-color: #0078f0;
          color: #fff;

          &:hover {
            background-color: #045af2;
          }
        }
      }
    }
  }
}
</style>
