import transform from "./transform";
import session from "./session";
import sys from "./sys";

export default {
  install(req) {
    req.session = session;
    req.sys = transform(sys);
  },
};
