<template>
  <div class="m-trial_succ_modal" flex="main:center cross:center">
    <div class="main_box">
      <img class="succ" src="@/assets/img/成功@3x 2.png" alt="成功" />
      <div class="title">提交成功</div>
      <div class="remark">请保持电话畅通，客服人员会在24小时内与您联系</div>
      <div class="info" flex="main:justify cross:center">
        <div flex-box="1">
          <div class="ttl">联系售前顾问</div>
          <div class="name">沈女士<br />19966289230</div>
        </div>
        <img
          class="code"
          flex-box="0"
          src="@/assets/img/smt.png"
          alt="二维码"
        />
      </div>
      <img
        class="close_btn"
        src="@/assets/img/ic_关闭@3x.png"
        @click="hideTrialSuccModal"
        alt="关闭"
      />
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  methods: {
    ...mapActions(["hideTrialSuccModal"]),
  },
};
</script>

<style lang="scss" scoped>
.m-trial_succ_modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(9, 21, 44, 0.6);
  z-index: 50;

  .main_box {
    position: relative;
    width: 22rem;
    height: 25.5rem;
    background-color: #fff;
    border-radius: 0.6rem;
    backdrop-filter: blur(0.5rem);
    padding: 1.25rem;

    .succ {
      display: block;
      width: 6rem;
      height: 6rem;
      margin: 3.25rem auto 0;
    }

    .title {
      margin-top: 1.5rem;
      font-size: 1.2rem;
      font-weight: bold;
      text-align: center;
      color: #1a1a1a;
      line-height: 1.5rem;
    }

    .remark {
      margin-top: 0.4rem;
      font-size: 0.8rem;
      text-align: center;
      color: #faad14;
      line-height: 1.1rem;
    }

    .info {
      margin: 2rem auto 0;
      width: 19.5rem;
      height: 7rem;
      background-color: #f0f1f6;
      border: 1px solid #ddd;
      padding: 1rem 1rem 0;
      box-sizing: border-box;

      .ttl {
        font-size: 1rem;
        font-weight: bold;
        color: #1a1a1a;
        line-height: 1.4rem;
      }

      .name {
        margin-top: 1.35rem;
        font-size: 0.8rem;
        color: #666;
        line-height: 1.15rem;
      }

      .code {
        display: block;
        width: 5rem;
        height: 5rem;
      }
    }

    .close_btn {
      position: absolute;
      right: 1.25rem;
      top: 1.25rem;
      width: 1.5rem;
      height: 1.5rem;
      cursor: pointer;
    }
  }
}
</style>
