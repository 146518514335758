import Vue from "vue";
import Vuex from "vuex";
import devices from "@/data/devices";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    trialModal: {
      visible: false,
    },
    trialSuccModal: {
      visible: false,
    },
    deviceDetail: {
      visible: false,
      info: null,
    },
  },
  mutations: {
    toggleTrialModal(state, visible) {
      state.trialModal.visible = visible;
    },
    toggletrialSuccModal(state, visible) {
      state.trialSuccModal.visible = visible;
    },
    toggleDeviceDetail(state, visible) {
      state.deviceDetail.visible = visible;
    },
    updateDeviceDetail(state, info) {
      state.deviceDetail.info = info;
    },
  },
  actions: {
    showTrialModal({ commit }) {
      commit("toggleTrialModal", true);
    },
    hideTrialModal({ commit }) {
      commit("toggleTrialModal", false);
    },
    showTrialSuccModal({ commit }) {
      commit("toggletrialSuccModal", true);
    },
    hideTrialSuccModal({ commit }) {
      commit("toggletrialSuccModal", false);
    },
    showDeviceDetail({ commit }, id) {
      const info = devices.find((d) => d.id === id);
      commit("updateDeviceDetail", info);
      commit("toggleDeviceDetail", true);
    },
    hideDeviceDetail({ commit }) {
      commit("updateDeviceDetail", null);
      commit("toggleDeviceDetail", false);
    },
  },
  modules: {},
});
